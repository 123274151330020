<template>
  <div id="addPatrolTask">
    <div class="info">
      <div class="mission-info">
        <div class="base-info">
          <div>
            任务名称:
            {{ this.taskName }}
          </div>
          <div>
            速度(米/秒):
            {{ this.patrolSpeed }}
          </div>
        </div>
        <div class="tips">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-menua-zu92"></use>
          </svg>
          机器人将按巡视点顺序巡视，到达最后一个巡视点后自动返回充电。
        </div>
      </div>

      <div id="map" style="margin-top: 25px">
        <div class="zoom-icon-wrapper">
          <div class="zoom-icon-plus" @click="zoomIn">
            <svg class="icon" aria-hidden="true" style="width: 22px;height: 22px;margin-bottom: 4px">
              <use xlink:href="#icon-menufangda"></use>
            </svg>
          </div>
          <div class="zoom-icon-minus" @click="zoomOut">
            <svg class="icon" aria-hidden="true" style="width: 22px;height: 22px;margin-bottom: 4px">
              <use xlink:href="#icon-menusuoxiao1"></use>
            </svg>
          </div>
        </div>
      </div>

      <div class="map-control">
        <div>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-menufangda"></use>
          </svg>
        </div>
        <div>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-menusuoxiao1"></use>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Marker from "@/assets/dian.png";
import CSSelect from "@/components/common/CSSelect";
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import AILabel from "ailabel";
export default {
  name: "patrolMap",
  components: {
    CSBreadcrumb,
    CSSelect,
  },
  props: ["item"],
  data() {
    return {
      //标记点列表
      id: 0,
      datas: [],
      rotatingSpeed: 0,
      setTimeoutNum: null,
      imageLeft: 0,
      mapUrl: "", // 地图路径
      descs: "", // 地图属性描述
      mapDesc: "", // 地图尺寸
      gMap: null, //AILabel实例
      gFirstFeatureLayer: null, //矢量图层实例(矩形，多边形等矢量)
      gFirstMarker: null,
      gFirstMaskLayer: null,
      drawingStyle: null,
      //矩形样式
      RectStyle: {
        lineWidth: 1.5, //边框宽度
        strokeStyle: "pink", //边框颜色
        fill: true, //是否填充背景色
        fillStyle: "rgb(255,255,255)", //背景颜色
      },
      buildingCode: "", //楼栋code
      mapId: "", //地图ID
      patrolSpeed: null, //巡视速度
      taskName: null, //任务名称
      startTime: null, //执行时间
    };
  },
  mounted() {
    console.log(this.item);
    this.mapUrl = this.item.mapUrl;
    let descs = this.item.descs?this.item.descs:JSON.stringify({width:0,height:0});
    let mapDesc = this.item.mapDesc;
    this.descs = JSON.parse(descs);
    this.mapDesc = JSON.parse(mapDesc);
    this.buildingCode = this.item.buildingCode;
    this.mapId = this.item.id;
    this.datas = JSON.parse(this.item.linePoint);
    this.patrolSpeed = this.item.patrolSpeed;
    this.startTime = this.itemtaskTime;
    this.taskName = this.item.patrolTaskName;
    this.id = this.item.id;
    this.rotatingSpeed = this.item.rotatingSpeed;
    this.initMap();
    this.addLineSegment(
      this.gMap,
      this.gFirstFeatureLayer,
      this.datas,
      this.drawingStyle
    );
  },
  created() {},
  methods: {
    //绘制路线
    drawLines(data, gMap, drawingStyle, isAdd = false) {
      //线段
      const pointFeature = new AILabel.Feature.Point(
        `${+new Date()}`, // id
        { ...data, sr: 0 }, // shape
        { name: "第一个矢量图层" }, // props
        { fillStyle: "#0EE46F" }
      );
      this.gFirstFeatureLayer.addFeature(pointFeature);
      //所有标记点的集合
      if (isAdd || isAdd === 0) {
        this.datas.splice(isAdd, 1);
      } else {
        this.datas.push(data);
      }
      //清空之前的标记
      this.removeInit();
      this.addLineSegment(
        gMap,
        this.gFirstFeatureLayer,
        this.datas,
        drawingStyle
      );
    },
    //清空点线
    removeInit() {
      //清空线
      var Features = this.gFirstFeatureLayer.getAllFeatures();
      for (var i = 0; i < Features.length; i++) {
        if (Features[i] && Features[i].id) {
          this.gFirstFeatureLayer.removeFeatureById(Features[i].id);
        }
      }

      //清空标记点
      var marker = this.gMap.markerLayer.getAllMarkers();
      for (var i = 0; i < marker.length; i++) {
        this.gMap.markerLayer.removeMarkerById(marker[i].id);
      }
      //清空序列编号
      var divs = document.getElementsByClassName("s");
      var domLength = divs.length;
      for (var i = 0; i < domLength; i++) {
        divs[0].remove();
      }
    },
    //添加线段
    addLineSegment(gMap, gFirstFeatureLayer, data, drawingStyle) {
      const scale = gMap.getScale();
      const width = drawingStyle.lineWidth / scale;
      drawingStyle = {
        strokeStyle: "#1ab394",
        fillStyle: "#00FFFF",
        lineJoin: "round",
        lineCap: "round",
        lineWidth: 2,
        arrow: true,
      };
      const polylineFeature = new AILabel.Feature.Polyline(
        `${+new Date()}`, // id
        { points: data, width }, // shape
        { name: "第一个矢量图层" }, // props
        drawingStyle // style
      );
      gFirstFeatureLayer.addFeature(polylineFeature);
      //删除线段
      //gFirstFeatureLayer.removeFeatureById(polylineFeature.id);
      //线段绘制完成添加标注
      for (var i = 0; i < data.length; i++) {
        this.addMask(gMap, data[i]);
      }
    },
    //添加标记
    addMask(gMap, data) {
      const marker = new AILabel.Marker(
        `${gMap.markerLayer.getAllMarkers().length + 1}maskted`, // id
        {
          src: Marker,
          position: data,
          offset: {
            x: -16,
            y: 32,
          },
        }, // markerInfo
        { name: "第一个marker注记" } // props
      );

      gMap.markerLayer.addMarker(marker);
      this.addOrderTag(gMap.markerLayer.getAllMarkers());
    },
    initMap() {
      let drawingStyle = {}; // 绘制过程中样式
      // 实例化
      const gMap = new AILabel.Map("map", {
        center: { x: 140, y: 240 }, //让图片居中
        zoom: 1000,
        mode: "PAN",
        refreshDelayWhenZooming: true, // 缩放时是否允许刷新延时，性能更优
        zoomWhenDrawing: true,
        panWhenDrawing: true,
        zoomWheelRatio: 5, // 控制滑轮缩放缩率[0, 10), 值越小，则缩放越快，反之越慢
        withHotKeys: true, // 关闭快捷键
      });
      //this.gMap = gMap;
      // 1. 图片层添加
      const gFirstImageLayer = new AILabel.Layer.Image(
        "layer-image", // id
        {
          src: this.mapUrl,
          width: this.mapDesc.width,
          height: this.mapDesc.height,
          crossOrigin: false, // 如果跨域图片，需要设置为true
        }, // imageInfo
        { name: "第一个图片图层" }, // props
        { zIndex: 5 } // style
      );
      // 添加到gMap对象
      gMap.addLayer(gFirstImageLayer);

      const gFirstFeatureLayer = new AILabel.Layer.Feature(
        "layer-feature", // id
        { name: "第一个矢量图层" }, // props
        { zIndex: 10 } // style
      );

      const gFirstFeaturePoint = new AILabel.Feature.Point(
        "first-feature-point", // id
        { x: this.descs.width, y: this.descs.height }, // shape
        { name: "第一个矢量图层" }, // props
        { fillStyle: "#f00", lineCap: "round" } // style
      );
      gFirstFeatureLayer.addFeature(gFirstFeaturePoint);

      gMap.addLayer(gFirstFeatureLayer);
      // data 代表r半径shape；data1代表sr半径shape
      gMap.events.on("drawDone", (type, data, data1) => {
        if (type === "MARKER") {
          this.addMask(gMap, data);
        } else if (type === "POINT") {
          //绘制点
          this.drawLines(data, gMap, drawingStyle);
        } else if (type === "POLYLINE") {
          this.addLineSegment(gMap, gFirstFeatureLayer, data, drawingStyle);
        }
      });
      //视野范围发生变化
      gMap.events.on("boundsChanged", (data) => {
        this.addOrderTag(gMap.markerLayer.getAllMarkers());
        return 2222;
      });
      gMap.events.on("featureSelected", (feature) => {
        gMap.setActiveFeature(feature);
      });
      gMap.events.on("featureUnselected", () => {
        // 取消featureSelected
        gMap.setActiveFeature(null);
      });
      gMap.setEditingColor("#1ab394");
      gMap.events.on("featureUpdated", (feature, shape) => {
        feature.updateShape(shape);
      });
      gMap.events.on("featureDeleted", ({ id: featureId }) => {
        gFirstFeatureLayer.removeFeatureById(featureId);
      });
      const gFirstMarker = new AILabel.Marker(
        "first-marker", // id
        {
          src: "./marker.png",
          position: {
            // 左上角相对中心点偏移量
            x: 250,
            y: 177,
          },
          offset: {
            x: -16,
            y: 32,
          },
        }, // markerInfo
        { name: "第一个marker注记" } // props
      );
      gFirstMarker.events.on("dragEnd", (marker, newPosition) => {
        marker.updatePosition(newPosition);
      });
      gFirstMarker.enableDragging();
      gMap.markerLayer.addMarker(gFirstMarker);

      this.gFirstFeatureLayer = gFirstFeatureLayer;
      this.drawingStyle = drawingStyle;
      this.gFirstMarker = gFirstMarker;
      this.gMap = gMap;
      window.onresize = function () {
        gMap && gMap.resize();
      };
    },
    zoomIn() {
      this.gMap.zoomIn();
    },
    zoomOut() {
      this.gMap.zoomOut();
    },
    getRle() {
      const rleData = this.gFirstMaskLayer.getRleData({
        x: 0,
        y: 0,
        width: 500,
        height: 354,
      });
    },
    // 获取所有features
    getFeatures() {
      const allFeatures = this.gFirstFeatureLayer.getAllFeatures();
    },
    // 实例销毁
    destroy() {
      this.gMap.destroy();
    },
    //为所有标记点添加标记顺序
    addOrderTag(tags) {
      clearTimeout(this.setTimeoutNum);
      this.setTimeoutNum = setTimeout(() => {
        var divs = document.getElementsByClassName("s");
        var domLength = divs.length;
        for (var i = 0; i < domLength; i++) {
          divs[0].remove();
        }
        for (var i = 0; i < tags.length; i++) {
          var img = document.getElementById(tags[i].id);
          if (img) {
            this.createdTag(img, i);
          }
        }
      }, 0);
    },
    //创建尾元素标记
    createdTag(dom, index) {
      var span = document.createElement("div");
      span.className = `s`;
      span.innerText = index;
      span.style.position = "absolute";
      span.style.left = dom.style.left;
      span.style.top = dom.style.top;
      span.style.cursor = "Pointer";
      span.style.height = "20px";
      span.style.width = "30px";
      span.style.color = "#FFFFFF";
      span.style.fontSize = "14px";
      span.style.textAlign = "center";
      dom.parentNode.append(span);
    },
  },
};
</script>

<style lang="stylus" scoped>
.tagSpan {
  text-align: center;
}

.info {
  position: relative;
  height: 600px;
}

.el-timer-class input.el-input__inner {
  height: 30px !important;
}

.mission-info {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.8);
  z-index: 1;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 1;
  z-index: 100;

  &.preview {
    width: 100%;
  }

  .tips {
    color: #999;
  }

  .base-info {
    margin-bottom: 10px;

    & > div {
      display: inline-block;

      input {
        padding: 0 10px;
        height: 30px;
        border: 1px solid #999999;
        opacity: 1;
        border-radius: 3px;
      }

      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }
  }
}

.btn-save {
  position: absolute;
  bottom: 30px;
  left: 50%;
  height: 40px;
  padding: 0 12px;
  width: 200px;
  color: #fff;
  transform: translateX(-50%);
  font-size: 24px;
  z-index: 100;
}

.button-wrap {
  padding-bottom: 10px;
}

#map {
  overflow: hidden;
  position: relative;
  height: 600px;
  border: 1px solid #ccc;
}

.zoom-icon-wrapper {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 1000;
}

.zoom-icon-plus {
  width: 40px;
  height: 40px;
  background-color: #1ab394;
  border-color: #1ab394;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  opacity: 0.9;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}

.zoom-icon-plus:hover {
  opacity: 1;
}

.zoom-icon-minus {
  margin-top: 6px;
  width: 40px;
  height: 40px;
  background-color: #1ab394;
  border-color: #1ab394;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  opacity: 0.9;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
}

.zoom-icon-minus:hover {
  opacity: 1;
}
</style>
